.about {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 236px 0;
    z-index: 10;

    @include is-screen-max($breakpoint-sm) {
        padding-top: 124px;
        padding-bottom: 109px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        background-color: #f9f9f9;
        z-index: -1;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 660px;
        max-width: 100%;

        @include is-screen-max($breakpoint-sm) {
            width: 290px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 70px;
        line-height: 84px;
        color: #6e28db;
        text-align: center;

        @include is-screen-max($breakpoint-sm) {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &__description {
        margin-top: 25px;
        font-size: 22px;
        line-height: 150%;
        text-align: center;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 30px;
            font-size: 17px;
        }
    }

    &__cubes {
        img {
            position: absolute;
            z-index: -1;

            &:nth-child(1) {
                right: calc(50% + 83px);
                top: -176px;
                height: 235px;

                @include is-screen-max($breakpoint-xxl) {
                    right: calc(50% + 8px);
                    top: -153px;
                    height: 204px;
                }

                @include is-screen-max($breakpoint-md) {
                    top: -120px;
                    height: 160px;
                }

                @include is-screen-max($breakpoint-sm) {
                    right: -33px;
                    top: -64px;
                    height: 109px;
                }
            }

            &:nth-child(2) {
                top: -64px;
                left: calc(50% + 50vw);
                transform: translateX(-50%);
                height: 509px;

                @include is-screen-max($breakpoint-xxl) {
                    top: -50px;
                    height: 440px;
                }

                @include is-screen-max($breakpoint-md) {
                    top: -50px;
                    height: 360px;
                }

                @include is-screen-max($breakpoint-sm) {
                    top: initial;
                    bottom: -110px;
                    left: -146px;
                    transform: none;
                    height: 234px;
                }
            }
        }
    }
}
