.form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 165px;
    padding-bottom: 125px;
    z-index: 10;

    @include is-screen-max($breakpoint-sm) {
        padding-top: 115px;
        padding-bottom: 135px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        background-color: #7f2ff4;
        z-index: -1;
    }

    &__title {
        font-weight: 600;
        font-size: 70px;
        line-height: 84px;
        color: #ffffff;
        text-align: center;

        @include is-screen-max($breakpoint-sm) {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 675px;
        max-width: 100%;
        margin-top: 60px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 48px;
        }
    }

    input,
    textarea {
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        appearance: none;
        background-color: transparent;
        font-family: "FuturaBookC", sans-serif;
    }

    &__input {
        input,
        textarea {
            display: block;
            width: 100%;
            padding: 16px 36px 12px;
            border: 1px solid #ffffff;
            border-radius: 14px;
            font-size: 19px;
            line-height: 24px;
            color: #ffffff;

            &::placeholder {
                color: #acb2ff;
                transition: color 0.3s;
            }

            &.error {
                border-color: #ff0000;
                color: #ff0000;

                &::placeholder {
                    color: #ff0000;
                }
            }
        }

        textarea {
            height: 115px;
            resize: none;
        }
    }

    &__submit {
        display: flex;
        justify-content: center;
        margin-top: 32px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 20px;
        }

        input {
            padding: 24px 100px;
            border-radius: 50px;
            background: linear-gradient(
                101.84deg,
                #febeff 15.98%,
                #b802df 115.35%
            );
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            color: #ffffff;
            text-align: center;
            white-space: nowrap;
            transition: box-shadow 0.3s;
            cursor: pointer;

            @include is-screen-min($breakpoint-sm) {
                &:hover {
                    box-shadow: 7px 11px 41.900001525878906px 0px
                            rgba(204, 0, 255, 0.7),
                        -4px -2px 39.400001525878906px 0px
                            rgba(248, 221, 255, 0.7);
                }
            }

            @include is-screen-max($breakpoint-sm) {
                padding: 20px 82px;
                font-size: 23px;
                line-height: 28px;
            }
        }
    }

    label.error {
        display: none !important;
    }

    &__response {
        font-size: 20px;
        line-height: 120%;
        color: #ffffff;
        text-align: center;

        &.is-error {
            color: #ff0000;
        }

        @include is-screen-max($breakpoint-sm) {
            font-size: 16px;
        }
    }

    &__objects {
        z-index: -1;

        img {
            position: absolute;

            &:nth-child(1) {
                left: calc(50% + 528px);
                top: 190px;
                width: 82px;

                @include is-screen-max($breakpoint-xxl) {
                    left: calc(50% + 398px);
                    top: 227px;
                    width: 63px;
                }

                @include is-screen-max($breakpoint-md) {
                    left: initial;
                    right: calc(50% + 145px);
                    top: -17px;
                }
            }

            &:nth-child(2) {
                top: 376px;
                left: calc(50% + 550px);
                width: 185px;

                @include is-screen-max($breakpoint-xxl) {
                    top: 372px;
                    left: calc(50% + 414px);
                    width: 144px;
                }

                @include is-screen-max($breakpoint-md) {
                    left: calc(50% + 82px);
                    top: initial;
                    bottom: -31px;
                }
            }

            &:nth-child(3) {
                top: -12px;
                left: calc(50% + 345px);
                width: 700px;
                z-index: -1;

                @include is-screen-max($breakpoint-xxl) {
                    top: 70px;
                    left: calc(50% + 220px);
                    width: 560px;
                }

                @include is-screen-max($breakpoint-md) {
                    display: none;
                }
            }
        }
    }
}
