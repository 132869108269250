body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    background-color: #ffffff;
    font: normal 400 22px / 150% "Poppins", sans-serif;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden auto;
    cursor: default;

    &.show-menu {
        overflow: hidden;
    }

    * {
        box-sizing: border-box;
        margin: 0;
    }

    img,
    svg {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;

        path {
            transition: fill 0.3s;
        }
    }

    iframe,
    video {
        display: block;
        max-width: 100%;
        max-height: 100%;
        border: none;
    }

    a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
    }

    .is-disabled,
    .is-disabled * {
        pointer-events: none;
        cursor: not-allowed;
    }

    .hide {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__content {
            width: 1530px;
            max-width: calc(100vw - 390px);

            @include is-screen-max($breakpoint-lg) {
                max-width: calc(100vw - 80px);
            }

            @include is-screen-max($breakpoint-sm) {
                max-width: calc(100vw - 40px);
            }
        }
    }
}
