.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 166px;
    padding-bottom: 160px;

    @include is-screen-max($breakpoint-sm) {
        padding-top: 115px;
        padding-bottom: 120px;
    }

    &__title {
        font-weight: 600;
        font-size: 70px;
        line-height: 84px;
        color: #6e28db;
        text-align: center;

        @include is-screen-max($breakpoint-sm) {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 1320px;
        margin-top: 60px;

        @include is-screen-max($breakpoint-xl) {
            width: 90vw;
        }

        @include is-screen-max($breakpoint-md) {
            width: 70vw;
        }

        @include is-screen-max($breakpoint-sm) {
            gap: 10px;
            width: 320px;
            max-width: 100%;
            margin-top: 30px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: calc(100% / 3);

        @include is-screen-max($breakpoint-md) {
            width: 50%;
        }

        @include is-screen-max($breakpoint-sm) {
            gap: 16px;
            width: 100%;
        }

        p {
            font-size: 22px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.8);
            text-align: center;

            @include is-screen-max($breakpoint-md) {
                font-size: 20px;
            }

            @include is-screen-max($breakpoint-sm) {
                font-size: 17px;
            }
        }
    }
}
