.footer {
    position: relative;
    padding: 100px 0;

    @include is-screen-max($breakpoint-sm) {
        padding: 104px 0 48px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        background-color: #000066;
        z-index: -1;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include is-screen-max($breakpoint-sm) {
            flex-direction: column;
            gap: 141px;
        }
    }

    &__logo {
        transition: opacity 0.3s;

        @include is-screen-min($breakpoint-sm) {
            &:hover {
                opacity: 0.6;
            }
        }

        img {
            height: 71px;

            @include is-screen-max($breakpoint-sm) {
                height: 33px;
            }
        }
    }

    .footer-contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 45px;

        @include is-screen-max($breakpoint-sm) {
            position: relative;
            align-items: center;
            gap: 42px;
            order: -1;

            &::before {
                content: "";
                position: absolute;
                bottom: -70px;
                left: 50%;
                transform: translateX(-50%);
                width: 104px;
                height: 1px;
                border-radius: 1px;
                background-color: #ffffff;
            }
        }

        &__title {
            font-family: "FuturaBookC", sans-serif;
            font-weight: 600;
            font-size: 65px;
            line-height: 100%;
            color: #ffffff;

            @include is-screen-max($breakpoint-sm) {
                font-size: 40px;
                line-height: 48px;
                text-align: center;
            }
        }

        &__list {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 25px 40px;

            @include is-screen-max($breakpoint-md) {
                flex-direction: column;
                align-items: flex-end;
            }

            @include is-screen-max($breakpoint-sm) {
                align-items: center;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            gap: 15px;

            @include is-screen-max($breakpoint-sm) {
                gap: 18px;
            }

            &[href] {
                transition: opacity 0.3s;

                @include is-screen-min($breakpoint-sm) {
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }

            img {
                height: 25px;
            }

            p {
                font-family: "FuturaBookC", sans-serif;
                font-size: 25px;
                line-height: 100%;
                color: #ffffff;
            }
        }
    }
}
