.header {
    position: fixed;
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;

    @include is-screen-max($breakpoint-sm) {
        top: 35px;
    }

    &__content {
        display: flex;
        align-items: center;
        padding: 30px 34px 30px 65px;
        border-radius: 60px;
        background-color: #efefef;
        overflow: hidden;

        @include is-screen-max($breakpoint-xl) {
            padding: 30px 30px 30px 45px;
        }

        @include is-screen-max($breakpoint-sm) {
            max-width: calc(100vw - 50px) !important;
            padding: 12px 24px 12px 30px;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: 48px;
        padding-right: 40px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 30px;

        @include is-screen-max($breakpoint-xl) {
            padding-right: 30px;
            margin-right: 20px;
        }

        @include is-screen-max($breakpoint-lg) {
            padding-right: 20px;
            margin-right: 10px;
        }

        @include is-screen-max($breakpoint-sm) {
            height: auto;
            padding-right: 0;
            border-right: none;
            margin-right: 0;
        }

        img {
            height: 42px;
            transition: transform 0.3s;

            @include is-screen-max($breakpoint-xl) {
                height: 36px;
            }

            @include is-screen-min($breakpoint-sm) {
                &:hover {
                    transform: scale(1.05);
                }
            }

            @include is-screen-max($breakpoint-sm) {
                height: 24px;
            }
        }
    }

    .header-nav {
        display: flex;
        align-items: center;
        flex-grow: 1;
        gap: 15px;

        @include is-screen-max($breakpoint-sm) {
            position: fixed;
            top: -35px;
            left: 50%;
            transform: translateX(-50%);
            flex-direction: column;
            gap: 30px;
            width: 100vw;
            height: 100vh;
            padding: 150px 25px 50px;
            background: linear-gradient(
                180deg,
                rgba(102, 36, 184, 0.7) 6.94%,
                rgba(213, 50, 178, 0.7) 100%
            );
            backdrop-filter: blur(32px);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;

            &.is-active {
                opacity: 1;
                pointer-events: initial;
            }
        }

        &__close {
            position: absolute;
            top: 50px;
            right: 50px;
            width: 30px;
            height: 30px;
            cursor: pointer;

            @include is-screen-min($breakpoint-sm) {
                display: none;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 25px;
                height: 2px;
                border-radius: 1px;
                background-color: #ffffff;
            }

            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        &__menu {
            display: flex;
            align-items: center;
            gap: 13px;
            margin-right: auto;
            padding: 0;
            list-style: none;

            @include is-screen-max($breakpoint-xl) {
                gap: 6px;
            }

            @include is-screen-max($breakpoint-sm) {
                flex-direction: column;
                align-items: flex-end;
                align-self: flex-end;
                gap: 18px;
                margin-right: 0;
                margin-bottom: auto;
            }

            li {
                a {
                    padding: 10px;
                    font-size: 15px;
                    line-height: 23px;
                    color: #1e1e1e;
                    transition: opacity 0.3s;

                    @include is-screen-max($breakpoint-sm) {
                        padding: 0 30px;
                        font-size: 30px;
                        line-height: 45px;
                        color: #ffffff;
                    }

                    @include is-screen-min($breakpoint-sm) {
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }

        &__link {
            padding: 10px;
            font-weight: 600;
            font-size: 15px;
            line-height: 23px;
            color: #000000;
            transition: opacity 0.3s;

            @include is-screen-min-max($breakpoint-lg, $breakpoint-xl) {
                display: none;
            }

            @include is-screen-max($breakpoint-md) {
                display: none;
            }

            @include is-screen-min($breakpoint-sm) {
                &:hover {
                    opacity: 0.6;
                }
            }

            @include is-screen-max($breakpoint-sm) {
                display: block;
                padding: 0;
                font-size: 20px;
                line-height: 30px;
                color: #ffffff;
            }
        }

        &__button {
            position: relative;
            transition: transform 0.3s;
            z-index: 10;

            @include is-screen-min($breakpoint-sm) {
                &:hover {
                    transform: scale(1.05);
                }
            }

            @include is-screen-max($breakpoint-sm) {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            p {
                padding: 11px 33px;
                border-radius: 30px;
                background: linear-gradient(
                    101.79deg,
                    #27daff 10.65%,
                    #5a67f8 52.9%,
                    #c090ef 96.73%
                );
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                color: #ffffff;
                text-align: center;

                @include is-screen-max($breakpoint-xl) {
                    padding: 11px 22px;
                }

                @include is-screen-max($breakpoint-sm) {
                    width: 100%;
                    max-width: 450px;
                    padding: 26px;
                    border-radius: 40px;
                    background: linear-gradient(
                        101.84deg,
                        #febeff 15.98%,
                        #b802df 115.35%
                    );
                    font-size: 23px;
                    line-height: 28px;
                }
            }

            img {
                position: absolute;
                left: 4.68%;
                top: 15.9%;
                width: 111%;
                max-width: initial;
                max-height: initial;
                z-index: -1;
                pointer-events: none;

                @include is-screen-max($breakpoint-sm) {
                    display: none;
                }
            }
        }
    }

    &__burger {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 6px;
        width: 40px;
        height: 40px;
        margin-left: auto;
        padding: 11px 7px;
        cursor: pointer;

        @include is-screen-min($breakpoint-sm) {
            display: none;
        }

        span {
            width: 26px;
            height: 2px;
            border-radius: 1px;
            background-color: #000066;

            &:last-child {
                width: 13px;
            }
        }
    }
}
