.hero {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 982px;

    @include is-screen-max($breakpoint-sm) {
        flex-direction: column;
        min-height: 844px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        background-color: #000066;
        z-index: -1;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 110px;
        margin-left: 65px;

        @include is-screen-max($breakpoint-md) {
            margin-left: 0;
        }

        @include is-screen-max($breakpoint-sm) {
            flex-grow: 1;
            align-items: center;
            margin-top: 150px;
            margin-bottom: 58px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 117px;
        line-height: 140px;
        color: #ffffff;

        @include is-screen-max($breakpoint-xxl) {
            font-size: 100px;
            line-height: 120px;
        }

        @include is-screen-max($breakpoint-md) {
            font-size: 90px;
            line-height: 100px;
        }

        @include is-screen-max($breakpoint-sm) {
            font-size: 50px;
            line-height: 60px;
            text-align: center;
        }
    }

    &__description {
        width: 470px;
        max-width: 100%;
        margin-top: 10px;
        font-size: 18px;
        line-height: 150%;
        color: rgba(255, 255, 255, 0.8);

        @include is-screen-max($breakpoint-xxl) {
            margin-top: 20px;
            font-size: 17px;
        }

        @include is-screen-max($breakpoint-sm) {
            width: 360px;
            text-align: center;
        }
    }

    &__button {
        margin-top: 60px;
        padding: 24px 65px;
        border-radius: 50px;
        background: linear-gradient(
            101.66deg,
            #3cfcff -8.25%,
            #1cc7ff 28.47%,
            #7f30f4 77.2%,
            #ff31fa 111.73%
        );
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: #ffffff;
        text-align: center;
        white-space: nowrap;
        transition: box-shadow 0.3s;

        @include is-screen-max($breakpoint-xxl) {
            padding: 21px 59px;
            font-size: 26px;
            line-height: 31px;
        }

        @include is-screen-min($breakpoint-sm) {
            &:hover {
                box-shadow: 18.640628814697266px 13.462677001953125px
                        53.8507080078125px 0px rgba(204, 0, 255, 0.7),
                    -8.284724235534668px -5.177952766418457px
                        56.64680099487305px 0px rgba(0, 233, 255, 0.5);
            }
        }

        @include is-screen-max($breakpoint-sm) {
            margin-top: auto;
            padding: 20px 50px;
            font-size: 23px;
            line-height: 28px;
        }
    }

    &__image {
        position: absolute;
        left: calc(50% + 23px);
        top: -32px;
        width: auto;
        height: 935px;
        max-width: initial;
        z-index: -1;

        @include is-screen-max($breakpoint-xxl) {
            left: calc(50% + 12px);
            top: -17px;
            height: 849px;
        }

        @include is-screen-max($breakpoint-sm) {
            left: 50%;
            transform: translateX(calc(-50% + 71px));
            top: 178px;
            height: 489px;
        }
    }
}
