@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@font-face {
  font-family: "FuturaBookC";
  src: url("../fonts/FuturaBookC/Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  background-color: #ffffff;
  font: normal 400 22px/150% "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden auto;
  cursor: default;
}
body.show-menu {
  overflow: hidden;
}
body * {
  box-sizing: border-box;
  margin: 0;
}
body img,
body svg {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
body img path,
body svg path {
  transition: fill 0.3s;
}
body iframe,
body video {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border: none;
}
body a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
body .is-disabled,
body .is-disabled * {
  pointer-events: none;
  cursor: not-allowed;
}
body .hide {
  display: none;
}
body .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
body .container__content {
  width: 1530px;
  max-width: calc(100vw - 390px);
}
@media screen and (max-width: 1279.9px) {
  body .container__content {
    max-width: calc(100vw - 80px);
  }
}
@media screen and (max-width: 767.9px) {
  body .container__content {
    max-width: calc(100vw - 40px);
  }
}

.header {
  position: fixed;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
}
@media screen and (max-width: 767.9px) {
  .header {
    top: 35px;
  }
}
.header__content {
  display: flex;
  align-items: center;
  padding: 30px 34px 30px 65px;
  border-radius: 60px;
  background-color: #efefef;
  overflow: hidden;
}
@media screen and (max-width: 1439.9px) {
  .header__content {
    padding: 30px 30px 30px 45px;
  }
}
@media screen and (max-width: 767.9px) {
  .header__content {
    max-width: calc(100vw - 50px) !important;
    padding: 12px 24px 12px 30px;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 48px;
  padding-right: 40px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 30px;
}
@media screen and (max-width: 1439.9px) {
  .header__logo {
    padding-right: 30px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 1279.9px) {
  .header__logo {
    padding-right: 20px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 767.9px) {
  .header__logo {
    height: auto;
    padding-right: 0;
    border-right: none;
    margin-right: 0;
  }
}
.header__logo img {
  height: 42px;
  transition: transform 0.3s;
}
@media screen and (max-width: 1439.9px) {
  .header__logo img {
    height: 36px;
  }
}
@media screen and (min-width: 768px) {
  .header__logo img:hover {
    transform: scale(1.05);
  }
}
@media screen and (max-width: 767.9px) {
  .header__logo img {
    height: 24px;
  }
}
.header .header-nav {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 15px;
}
@media screen and (max-width: 767.9px) {
  .header .header-nav {
    position: fixed;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    gap: 30px;
    width: 100vw;
    height: 100vh;
    padding: 150px 25px 50px;
    background: linear-gradient(180deg, rgba(102, 36, 184, 0.7) 6.94%, rgba(213, 50, 178, 0.7) 100%);
    backdrop-filter: blur(32px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }
  .header .header-nav.is-active {
    opacity: 1;
    pointer-events: initial;
  }
}
.header .header-nav__close {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .header .header-nav__close {
    display: none;
  }
}
.header .header-nav__close::before, .header .header-nav__close::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25px;
  height: 2px;
  border-radius: 1px;
  background-color: #ffffff;
}
.header .header-nav__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.header .header-nav__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.header .header-nav__menu {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-right: auto;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 1439.9px) {
  .header .header-nav__menu {
    gap: 6px;
  }
}
@media screen and (max-width: 767.9px) {
  .header .header-nav__menu {
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    gap: 18px;
    margin-right: 0;
    margin-bottom: auto;
  }
}
.header .header-nav__menu li a {
  padding: 10px;
  font-size: 15px;
  line-height: 23px;
  color: #1e1e1e;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767.9px) {
  .header .header-nav__menu li a {
    padding: 0 30px;
    font-size: 30px;
    line-height: 45px;
    color: #ffffff;
  }
}
@media screen and (min-width: 768px) {
  .header .header-nav__menu li a:hover {
    opacity: 0.6;
  }
}
.header .header-nav__link {
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: #000000;
  transition: opacity 0.3s;
}
@media screen and (min-width: 1280px) and (max-width: 1439.9px) {
  .header .header-nav__link {
    display: none;
  }
}
@media screen and (max-width: 1023.9px) {
  .header .header-nav__link {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .header .header-nav__link:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 767.9px) {
  .header .header-nav__link {
    display: block;
    padding: 0;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }
}
.header .header-nav__button {
  position: relative;
  transition: transform 0.3s;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .header .header-nav__button:hover {
    transform: scale(1.05);
  }
}
@media screen and (max-width: 767.9px) {
  .header .header-nav__button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.header .header-nav__button p {
  padding: 11px 33px;
  border-radius: 30px;
  background: linear-gradient(101.79deg, #27daff 10.65%, #5a67f8 52.9%, #c090ef 96.73%);
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}
@media screen and (max-width: 1439.9px) {
  .header .header-nav__button p {
    padding: 11px 22px;
  }
}
@media screen and (max-width: 767.9px) {
  .header .header-nav__button p {
    width: 100%;
    max-width: 450px;
    padding: 26px;
    border-radius: 40px;
    background: linear-gradient(101.84deg, #febeff 15.98%, #b802df 115.35%);
    font-size: 23px;
    line-height: 28px;
  }
}
.header .header-nav__button img {
  position: absolute;
  left: 4.68%;
  top: 15.9%;
  width: 111%;
  max-width: initial;
  max-height: initial;
  z-index: -1;
  pointer-events: none;
}
@media screen and (max-width: 767.9px) {
  .header .header-nav__button img {
    display: none;
  }
}
.header__burger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  width: 40px;
  height: 40px;
  margin-left: auto;
  padding: 11px 7px;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .header__burger {
    display: none;
  }
}
.header__burger span {
  width: 26px;
  height: 2px;
  border-radius: 1px;
  background-color: #000066;
}
.header__burger span:last-child {
  width: 13px;
}

.footer {
  position: relative;
  padding: 100px 0;
}
@media screen and (max-width: 767.9px) {
  .footer {
    padding: 104px 0 48px;
  }
}
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #000066;
  z-index: -1;
}
.footer__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767.9px) {
  .footer__content {
    flex-direction: column;
    gap: 141px;
  }
}
.footer__logo {
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  .footer__logo:hover {
    opacity: 0.6;
  }
}
.footer__logo img {
  height: 71px;
}
@media screen and (max-width: 767.9px) {
  .footer__logo img {
    height: 33px;
  }
}
.footer .footer-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 45px;
}
@media screen and (max-width: 767.9px) {
  .footer .footer-contacts {
    position: relative;
    align-items: center;
    gap: 42px;
    order: -1;
  }
  .footer .footer-contacts::before {
    content: "";
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    width: 104px;
    height: 1px;
    border-radius: 1px;
    background-color: #ffffff;
  }
}
.footer .footer-contacts__title {
  font-family: "FuturaBookC", sans-serif;
  font-weight: 600;
  font-size: 65px;
  line-height: 100%;
  color: #ffffff;
}
@media screen and (max-width: 767.9px) {
  .footer .footer-contacts__title {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }
}
.footer .footer-contacts__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px 40px;
}
@media screen and (max-width: 1023.9px) {
  .footer .footer-contacts__list {
    flex-direction: column;
    align-items: flex-end;
  }
}
@media screen and (max-width: 767.9px) {
  .footer .footer-contacts__list {
    align-items: center;
  }
}
.footer .footer-contacts__item {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media screen and (max-width: 767.9px) {
  .footer .footer-contacts__item {
    gap: 18px;
  }
}
.footer .footer-contacts__item[href] {
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  .footer .footer-contacts__item[href]:hover {
    opacity: 0.6;
  }
}
.footer .footer-contacts__item img {
  height: 25px;
}
.footer .footer-contacts__item p {
  font-family: "FuturaBookC", sans-serif;
  font-size: 25px;
  line-height: 100%;
  color: #ffffff;
}

.hero {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 982px;
}
@media screen and (max-width: 767.9px) {
  .hero {
    flex-direction: column;
    min-height: 844px;
  }
}
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #000066;
  z-index: -1;
}
.hero__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 110px;
  margin-left: 65px;
}
@media screen and (max-width: 1023.9px) {
  .hero__content {
    margin-left: 0;
  }
}
@media screen and (max-width: 767.9px) {
  .hero__content {
    flex-grow: 1;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 58px;
  }
}
.hero__title {
  font-weight: 600;
  font-size: 117px;
  line-height: 140px;
  color: #ffffff;
}
@media screen and (max-width: 1579.9px) {
  .hero__title {
    font-size: 100px;
    line-height: 120px;
  }
}
@media screen and (max-width: 1023.9px) {
  .hero__title {
    font-size: 90px;
    line-height: 100px;
  }
}
@media screen and (max-width: 767.9px) {
  .hero__title {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }
}
.hero__description {
  width: 470px;
  max-width: 100%;
  margin-top: 10px;
  font-size: 18px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 1579.9px) {
  .hero__description {
    margin-top: 20px;
    font-size: 17px;
  }
}
@media screen and (max-width: 767.9px) {
  .hero__description {
    width: 360px;
    text-align: center;
  }
}
.hero__button {
  margin-top: 60px;
  padding: 24px 65px;
  border-radius: 50px;
  background: linear-gradient(101.66deg, #3cfcff -8.25%, #1cc7ff 28.47%, #7f30f4 77.2%, #ff31fa 111.73%);
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  transition: box-shadow 0.3s;
}
@media screen and (max-width: 1579.9px) {
  .hero__button {
    padding: 21px 59px;
    font-size: 26px;
    line-height: 31px;
  }
}
@media screen and (min-width: 768px) {
  .hero__button:hover {
    box-shadow: 18.6406288147px 13.462677002px 53.8507080078px 0px rgba(204, 0, 255, 0.7), -8.2847242355px -5.1779527664px 56.6468009949px 0px rgba(0, 233, 255, 0.5);
  }
}
@media screen and (max-width: 767.9px) {
  .hero__button {
    margin-top: auto;
    padding: 20px 50px;
    font-size: 23px;
    line-height: 28px;
  }
}
.hero__image {
  position: absolute;
  left: calc(50% + 23px);
  top: -32px;
  width: auto;
  height: 935px;
  max-width: initial;
  z-index: -1;
}
@media screen and (max-width: 1579.9px) {
  .hero__image {
    left: calc(50% + 12px);
    top: -17px;
    height: 849px;
  }
}
@media screen and (max-width: 767.9px) {
  .hero__image {
    left: 50%;
    transform: translateX(calc(-50% + 71px));
    top: 178px;
    height: 489px;
  }
}

.about {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 236px 0;
  z-index: 10;
}
@media screen and (max-width: 767.9px) {
  .about {
    padding-top: 124px;
    padding-bottom: 109px;
  }
}
.about::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #f9f9f9;
  z-index: -1;
}
.about__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 660px;
  max-width: 100%;
}
@media screen and (max-width: 767.9px) {
  .about__content {
    width: 290px;
  }
}
.about__title {
  font-weight: 600;
  font-size: 70px;
  line-height: 84px;
  color: #6e28db;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .about__title {
    font-size: 40px;
    line-height: 48px;
  }
}
.about__description {
  margin-top: 25px;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .about__description {
    margin-top: 30px;
    font-size: 17px;
  }
}
.about__cubes img {
  position: absolute;
  z-index: -1;
}
.about__cubes img:nth-child(1) {
  right: calc(50% + 83px);
  top: -176px;
  height: 235px;
}
@media screen and (max-width: 1579.9px) {
  .about__cubes img:nth-child(1) {
    right: calc(50% + 8px);
    top: -153px;
    height: 204px;
  }
}
@media screen and (max-width: 1023.9px) {
  .about__cubes img:nth-child(1) {
    top: -120px;
    height: 160px;
  }
}
@media screen and (max-width: 767.9px) {
  .about__cubes img:nth-child(1) {
    right: -33px;
    top: -64px;
    height: 109px;
  }
}
.about__cubes img:nth-child(2) {
  top: -64px;
  left: calc(50% + 50vw);
  transform: translateX(-50%);
  height: 509px;
}
@media screen and (max-width: 1579.9px) {
  .about__cubes img:nth-child(2) {
    top: -50px;
    height: 440px;
  }
}
@media screen and (max-width: 1023.9px) {
  .about__cubes img:nth-child(2) {
    top: -50px;
    height: 360px;
  }
}
@media screen and (max-width: 767.9px) {
  .about__cubes img:nth-child(2) {
    top: initial;
    bottom: -110px;
    left: -146px;
    transform: none;
    height: 234px;
  }
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 166px;
  padding-bottom: 160px;
}
@media screen and (max-width: 767.9px) {
  .services {
    padding-top: 115px;
    padding-bottom: 120px;
  }
}
.services__title {
  font-weight: 600;
  font-size: 70px;
  line-height: 84px;
  color: #6e28db;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .services__title {
    font-size: 40px;
    line-height: 48px;
  }
}
.services__list {
  display: flex;
  flex-wrap: wrap;
  width: 1320px;
  margin-top: 60px;
}
@media screen and (max-width: 1439.9px) {
  .services__list {
    width: 90vw;
  }
}
@media screen and (max-width: 1023.9px) {
  .services__list {
    width: 70vw;
  }
}
@media screen and (max-width: 767.9px) {
  .services__list {
    gap: 10px;
    width: 320px;
    max-width: 100%;
    margin-top: 30px;
  }
}
.services__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 33.3333333333%;
}
@media screen and (max-width: 1023.9px) {
  .services__item {
    width: 50%;
  }
}
@media screen and (max-width: 767.9px) {
  .services__item {
    gap: 16px;
    width: 100%;
  }
}
.services__item p {
  font-size: 22px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}
@media screen and (max-width: 1023.9px) {
  .services__item p {
    font-size: 20px;
  }
}
@media screen and (max-width: 767.9px) {
  .services__item p {
    font-size: 17px;
  }
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 165px;
  padding-bottom: 125px;
  z-index: 10;
}
@media screen and (max-width: 767.9px) {
  .form {
    padding-top: 115px;
    padding-bottom: 135px;
  }
}
.form::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #7f2ff4;
  z-index: -1;
}
.form__title {
  font-weight: 600;
  font-size: 70px;
  line-height: 84px;
  color: #ffffff;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .form__title {
    font-size: 40px;
    line-height: 48px;
  }
}
.form__body {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 675px;
  max-width: 100%;
  margin-top: 60px;
}
@media screen and (max-width: 767.9px) {
  .form__body {
    margin-top: 48px;
  }
}
.form input,
.form textarea {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  font-family: "FuturaBookC", sans-serif;
}
.form__input input,
.form__input textarea {
  display: block;
  width: 100%;
  padding: 16px 36px 12px;
  border: 1px solid #ffffff;
  border-radius: 14px;
  font-size: 19px;
  line-height: 24px;
  color: #ffffff;
}
.form__input input::placeholder,
.form__input textarea::placeholder {
  color: #acb2ff;
  transition: color 0.3s;
}
.form__input input.error,
.form__input textarea.error {
  border-color: #ff0000;
  color: #ff0000;
}
.form__input input.error::placeholder,
.form__input textarea.error::placeholder {
  color: #ff0000;
}
.form__input textarea {
  height: 115px;
  resize: none;
}
.form__submit {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
@media screen and (max-width: 767.9px) {
  .form__submit {
    margin-top: 20px;
  }
}
.form__submit input {
  padding: 24px 100px;
  border-radius: 50px;
  background: linear-gradient(101.84deg, #febeff 15.98%, #b802df 115.35%);
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  transition: box-shadow 0.3s;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .form__submit input:hover {
    box-shadow: 7px 11px 41.9000015259px 0px rgba(204, 0, 255, 0.7), -4px -2px 39.4000015259px 0px rgba(248, 221, 255, 0.7);
  }
}
@media screen and (max-width: 767.9px) {
  .form__submit input {
    padding: 20px 82px;
    font-size: 23px;
    line-height: 28px;
  }
}
.form label.error {
  display: none !important;
}
.form__response {
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  text-align: center;
}
.form__response.is-error {
  color: #ff0000;
}
@media screen and (max-width: 767.9px) {
  .form__response {
    font-size: 16px;
  }
}
.form__objects {
  z-index: -1;
}
.form__objects img {
  position: absolute;
}
.form__objects img:nth-child(1) {
  left: calc(50% + 528px);
  top: 190px;
  width: 82px;
}
@media screen and (max-width: 1579.9px) {
  .form__objects img:nth-child(1) {
    left: calc(50% + 398px);
    top: 227px;
    width: 63px;
  }
}
@media screen and (max-width: 1023.9px) {
  .form__objects img:nth-child(1) {
    left: initial;
    right: calc(50% + 145px);
    top: -17px;
  }
}
.form__objects img:nth-child(2) {
  top: 376px;
  left: calc(50% + 550px);
  width: 185px;
}
@media screen and (max-width: 1579.9px) {
  .form__objects img:nth-child(2) {
    top: 372px;
    left: calc(50% + 414px);
    width: 144px;
  }
}
@media screen and (max-width: 1023.9px) {
  .form__objects img:nth-child(2) {
    left: calc(50% + 82px);
    top: initial;
    bottom: -31px;
  }
}
.form__objects img:nth-child(3) {
  top: -12px;
  left: calc(50% + 345px);
  width: 700px;
  z-index: -1;
}
@media screen and (max-width: 1579.9px) {
  .form__objects img:nth-child(3) {
    top: 70px;
    left: calc(50% + 220px);
    width: 560px;
  }
}
@media screen and (max-width: 1023.9px) {
  .form__objects img:nth-child(3) {
    display: none;
  }
}